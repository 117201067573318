import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 优惠券列表
  {
    path: '/discountcoupon',
    name: 'discountcoupon',
    component: () => import('@/views/DiscountCoupon.vue')
  },
  // 话费充值
  {
    path: '/prepaidrefill',
    name: 'prepaidrefill',
    component: () => import('@/views/PrepaidRefill.vue')
  },
  // 我的订单
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order.vue')
  },
  // 支付成功(从微信购买券包后直接跳转)
  {
    path: '/rechargesuccess',
    name: 'rechargesuccess',
    component: () => import('@/views/RechargeSuccess.vue')
  },
  // 券包领取成功(从短信链接跳转)
  {
    path: '/getcouponsuccess',
    name: 'getcouponsuccess',
    component: () => import('@/views/GetCouponSuccess.vue')
  },
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  // 客服页
  {
      path: '/customerservice',
      name: 'customerservice',
      component: () => import('@/views/CustomerService.vue')
  },
  // 用户协议页面
  {
    path:`/useraggrement`,
    name:'useraggrement',
    component: () => import('@/views/UserAggrement.vue')
  },  
  // 隐私协议
  {
    path:`/privacypolicy`,
    name:'privacypolicy',
    component:()=> import('@/views/PrivacyPolicy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
