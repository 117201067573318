import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    channelId: ''
  },
  getters: {
  },
  mutations: {
    SET_CHANNELID: (state, channelId) => {
      state.channelId = channelId
    }
  },
  actions: {
  },
  modules: {
  }
})
