import Cookies from 'js-cookie'

const TokenKey = 'ccltoken'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 7 }) // expires 存储时间
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setChannelId() {
  return Cookies.set('channelId',channelId, { expires: 7 })
}
