<template>
  <div class="home">
    <header class="header">
      <!-- <div style="height:190px"></div> -->
      <!-- <img src="../assets/编组 20.png" width="100%" fit="cover" > -->
      <div style="width:100%;height:30px;padding:5px 0 0 5px">
        <div style="width:20px; height: 20px;overflow:hidden;border-radius:35%">
          <img src="../assets/logo-c.jpg" style="width:20px; height: 20px; float:left" >
        </div>
      </div>
      <div class="text-bg df aic">
        <div class="df fdc f1 aifs">
          <h2 class="bigtitle">已锁定百元话费券！</h2>
          <div class="countDownTitle">
            支付剩余时间
            <van-count-down class="countComponent" ref="countDown" millisecond :time="time"  @finish="reset">
              <template #default="timeData">
                <span class="block">{{ '0'+timeData.minutes}}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds<10?'0'+timeData.seconds:timeData.seconds}}</span>
                <span class="colon">:</span>
                <span class="block">{{ Math.round(timeData.milliseconds/10)<10?'0'+ Math.round(timeData.milliseconds/10):Math.round(timeData.milliseconds/10) }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
        <div class="df fdc aife rightbox">
          <!-- 规则 -->
          <img class="mt10" src="../assets/编组 4.png" width="100%" @click="openbox">
          <!-- 客服中心 -->
          <img class="mt10" src="../assets/编组 3.png" width="100%" @click="openCustomerServicePage">
        </div>
      </div>
    </header>
    <main class="main">
      <div class="mainbox">
        <div class="bgbox">
            <ul class="operatorIcon">
              <li>
                <img src="../assets/中国电信-01.png"/>
              </li>
              <li>
                <img class ="mobile" src="../assets/中国移动-01.png"/>
              </li>
              <li>
                <img class="Unicom" src="../assets/中国联通-01.png"/>
              </li>
            </ul>
          <van-form validate-first>
            <!-- 通过 pattern 进行正则校验 -->
            <!-- 输入框 -->
            <div class="inputbox">
              <van-field
                v-model="keyvalue"
                name="pattern"
                placeholder="请输入手机号"
                :formatter="formatter"
                @keyup="keyInput"
                maxlength="11"
                type="tel"
                ref="input"
                style="height: 40px; border-bottom: 2px solid #f1f1f1; font-size: 26px; padding-bottom: 35px; margin-top: 20px"
                clearable
                @blur="verify"
              />
              <header class="subtileconpon">
                <span>充话费券</span>
              </header>
              <img src="@/assets/电话薄.png" width="35px">
            </div>
            <!-- 活动内容卡片 -->
            <div class="df jcse aic mt20 cardcontainer">
              <!-- 券已抢光样式 -->
              <div class="df fdc aic jcse eventscardsold">
                <h2>50元</h2>
                <span>已抢光</span>
              </div>
              <!-- 卡片选中样式 -->
              <div class="df fdc aic jcse eventscardon">
                <span class="withbg">话费充值券</span>
                <h2>{{resData.couponActualPrice}}元</h2>
                <span>售价: {{resData.price}}元</span>
                <img class="icon" src="../assets/蓝色选中对勾.png"/>
              </div>
              <!-- 券已抢光样式 -->
              <div class="df fdc aic jcse eventscardsold">
                <h2>50元</h2>
                <span>已抢光</span>
              </div>
            </div>
          </van-form>
        </div>
        <div class="bgbox middlebox df fdc aifs jcsb">
            <span class="explainTitle">支付方式</span>
            <div class="df jcsb aic wechatbox">
              <div class="df aic">
                <img class="mr5" src="@/assets/wechat.png" width="28px" height="28px">
                <span> <label><input v-model="radioVal" type="radio" value="0" @change="getRadioVal"> 微信</label></span>
                <span class="colorred">（首单随机立减,最高至免单）</span>
              </div>
              <!-- <img class="icon" src="@/assets/蓝色选中对勾.png" width="25px" /> -->
            </div>
            <div class="mb10 explain">
              实际支付金额:
              <span>{{resData.price}}元</span>
            </div>
            <div class="explain">
              实际到账话费券:
              <span>{{resData.couponActualPrice}}元</span>
            </div>
            <div style="height: 10px;">
              <van-checkbox v-model="Aggrementchecked" class="userAgreement">
                <p>已阅读并同意 <a @click="gotoUserAggrement"> 《用户协议》 </a> & <a @click="gotoPrivacyPolicy">《隐私协议》</a></p>
              </van-checkbox>
            </div>
        </div>
        <!-- 底部话费券包说明 -->
        <div class="bgbox">
          <div class="explaincontent">
            <h3>话费包说明</h3>
            <p class="large">
              1、产品介绍
            </p>
            <p class="little">
              {{this.couponContent}}
            </p>
            <p class="large">
              2、活动规则
            </p>
            <p class="little">
              1）、购买券包后可立即使用，不限制使用手机号，不限制单日使用次数；
            </p>
            <p class="little">
              2）、优惠券购买和充值均在【充充乐】进行，并配备7天*18小时在线人工客服；
            </p>
            <p class="little">
              3）、券包支持7天无理由退款，超过7天不予退款；
            </p>
            <p class="little">
              4）、更多疑问，请咨询在线人工客服或拨打客服电话：18512601835；
            </p>
            <p class="little">
              5）、本活动由成都小蓝皮科技有限公司提供技术和服务支持。
            </p>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer df jcsb aife">
      <div class="left">
        <span>会员价￥</span>
        <strong>{{resData.price}}</strong>
      </div>
      <span class="right" @click="onSubmit">确认支付</span>
    </footer>
    <!-- 规则弹出框 -->
    <van-popup closeable close-icon="close" style="width: 343px; height: 310px; padding: 0 10px" round v-model="boxshow">
      <div class="explaincontent">
        <h3>话费包说明</h3>
        <p class="large">
          1、产品介绍
        </p>
        <p class="little">
          {{this.couponContent}}
        </p>
        <p class="large">
          2、活动规则
        </p>
        <p class="little">
          1）、购买券包后可立即使用，不限制使用手机号，不限制单日使用次数；
        </p>
        <p class="little">
          2）、优惠券购买和充值均在【充充乐】进行，并配备7天*18小时在线人工客服；
        </p>
        <p class="little">
          3）、券包支持7天无理由退款，超过7天不予退款；
        </p>
        <p class="little">
          4）、更多疑问，请咨询在线人工客服；
        </p>
        <p class="little">
          5）、本活动由成都小蓝皮科技有限公司提供技术和服务支持。
        </p>
      </div>
    </van-popup>
    <!-- 客服二维码弹框 -->
    <van-popup v-model="openerweima" round>
        <div class="df fdc aic jcsa" style="height: 220px; width: 220px">
            <img src="@/assets/erweima.jpg" alt="">
            <span>长按复制图片后打开微信</span>
        </div>
    </van-popup>
    <!-- loading动画 -->
    <!-- <van-loading class="loadingimg" v-show="isloading" size="24px" vertical>正在充值...</van-loading> -->
  </div>
</template>

<script>
import {getpageInfo, payForCoupon, searchOrder} from '@/api/index'
import { decode } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'
import { Dialog } from 'vant'
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      couponContent:'',
      resData: {},
      boxshow:false,
      timeData:{minutes:5,seconds:0},
      time:300000, /* 5分钟倒计时 */
      keyvalue: '',
      pattern: /^(12[0-9]|13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/,
      price:'',
      channelId:'',
      unId:'',
      openerweima: false,
      // isloading:false,
      a_oId:'',
      phone:'', //获取到的加密后的电话
      Aggrementchecked:false,
      orderNo:'',
      radioVal: 0 // 用于设置默认选中项
    }
  },
  created(){
    this.a_oId = this.$route.query.a_oId
    if(this.$route.query.phone){
        this.phone = this.$route.query.phone
        if(this.pattern.test(this.phone)){
          this.keyvalue = this.phone
        }else{
          this.keyvalue = decode(decodeURIComponent(this.phone))
        }
    }else{
      if(localStorage.getItem('phone')){
        this.keyvalue = localStorage.getItem('phone')
      }
    }
    if(!localStorage.getItem('AOID') && this.a_oId){
      localStorage.setItem('AOID', this.a_oId)
    }else if(!this.a_oId){
      this.a_oId = localStorage.getItem('AOID')
    }
    this.render()
  },
  mounted() {
    if(this.keyvalue.length === 11){
      this.Aggrementchecked = true
    }
    //正式环境才会3秒跳转
    const reg = RegExp(/pay.nannani.com/)
    if(reg.test(location.href)){
      setTimeout(() => {
        if(this.keyvalue.length === 11){
          //this.onSubmit()
        }
      },3000)
    }
  },
  methods: {
    // 打开弹出框
    openbox() {
      this.boxshow = true
    },
    keyInput() {
      if(this.keyvalue.length === 11){
        this.boxshow = true
        setTimeout(() => {
         // this.onSubmit()
        },3000)
      }
    },
    getRadioVal() {
       console.log(this.radioVal);
    },
    render(){
      if(this.$route.query.channelId){
        this.channelId = this.$route.query.channelId
        this.$store.commit('SET_CHANNELID',this.channelId)
      }else if(localStorage.getItem('channelId')){
        this.channelId = localStorage.getItem('channelId')
      }
      localStorage.setItem('channelId',this.channelId)
      let uuid
      if(!localStorage.getItem('unId')){
        uuid = uuidv4()
        localStorage.setItem('unId', uuid)
      }else {
        uuid = localStorage.getItem('unId')
      }
      getpageInfo({channelId: this.channelId, channelUnId: uuid}).then(res=>{
        this.couponContent = res.data.couponContents.join(',') + '；'
        this.resData = res.data
        // this.price=res.data
      })
    },
    reset(){
      this.$refs.countDown.reset()
    },
    // 过滤输入的内容只能是数字
    formatter(value) {
      return value.replace(/[^0-9]/, '');
    },
    verify(){
      if(this.keyvalue){
        if(this.pattern.test(this.keyvalue)){
          this.showkeyboard = false
          this.Aggrementchecked = true;
        }else{
          this.$toast('请输入正确的手机号')
        }
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        let n = 3
        var timer = setInterval(()=>{
          searchOrder({orderNo:this.orderNo}).then(res1 => {
            if(res1.data.payState == 1){
              clearInterval(timer)
              this.$toast('支付成功,即将跳转登录')
              this.$router.push(`/getcouponsuccess?phonenum=${res1.data.phone}`)
              done()
            }else if(res1.data.payState == 0){
              clearInterval(timer)
              this.$toast('支付失败')
              done()
            }else if(res1.data.payState == 2 && n === 1){
              clearInterval(timer)
              this.$toast('支付失败')
              done()
            }else{
              n--
            }
          })
        },1500)
      } else {
        clearInterval(timer)
        done()
      }
    },
    // 发请求购买券包
    onSubmit() {
      if(this.keyvalue && this.pattern.test(this.keyvalue)){
            payForCoupon({type:this.radioVal,phone:this.keyvalue,threeNo:this.a_oId,channelId:this.channelId}).then(res=>{
              localStorage.setItem('phone',this.keyvalue)
              this.orderNo = res.data.orderNo
              setTimeout(()=>{
                Dialog.confirm({
                  title: '是否已完成支付',
                  confirmButtonText: '是',
                  cancelButtonText: '否',
                  beforeClose: this.beforeClose,
                }).catch(() => {
                  // on cancel
                })
              },3000)
              window.open(res.data.h5Url,'_self')
            })
      }else if(!this.keyvalue){
        this.$toast('请先输入手机号')
      }else if(!this.pattern.test(this.keyvalue)){
        this.$toast('请输入正确的手机号')
      }
    },
    // 打开客服页
    openCustomerServicePage(){
      this.$router.push(`/customerservice`)
    },
    // 打开用户协议
    gotoUserAggrement(){
      this.Aggrementchecked=true;
      this.$router.push('/useraggrement')
    },
    // 打开隐私政策
    gotoPrivacyPolicy()
    {
      this.Aggrementchecked=true;
      this.$router.push(`/privacypolicy`)
    }
  },
}
</script>

<style lang="scss" scoped>
  $ColorDeepblue: #3874f6;
  .home{
    width: 100%;
    overflow: auto;
    // height: 100%;
    background-color: #f6f6f6;;
    .loadingimg{
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 999;
      background: #404040;
      padding: 30px 20px;
      border-radius: 20px;
    }
    .header{
      position: relative;
      width: 100%;
      height: 190px;
      background: $ColorDeepblue;
      // 头部广告部分文字部分
      .text-bg{
        position:absolute;
        width: 100%;
        height: 100%;
        top: -30px;
        left: 2%;
        color: #fff;
        text-align: center;
        h2,h3{
          font-weight: normal;
        }
        .bigtitle{
            width: 100%;
            text-align: center;
            font-size: 40px;
            white-space: nowrap;
        }
        .rightbox{
          position: absolute;
          right: 0;
          width: 60px;
        }
      }
      // 倒计时样式
     .countDownTitle{
        font-size: 20px;
        color: #fff;
        text-align: center;
        margin-top: 10px;
        white-space: nowrap;
        position: relative;
        left:15%;
      }
      .countComponent
      {
        display: inline;
      }
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        width: 24px;
        height: 22px;
        line-height: 20px;
        font-family: ArialMT;
        color: $ColorDeepblue;
        font-weight: 600;
        border-radius: 2px;
        font-size: 16px;
        text-align: center;
        background: white;
      }
    }
    .main{
      // position: relative;
      width: 100%;
      padding:0 16px 80px;
      // 中间盒子
      .mainbox{
        width: 100%;
        position: relative;
        top: -70px;
        left: 50%;
        z-index: 9;
        transform: translateX(-50%);
        .bgbox{
        padding: 0 10px;
        background-color: #fff;
        border-radius: 15px;
        margin-top: 20px;



        // 输入框
          .inputbox{
            position: relative;
            img{
              position: absolute;
              right: 10px;
              top: 30%;
              transform:translateY(-50%) ;
            }
          }
          .operatorIcon{
            display: flex;
            width: 100%;
            height: 60px;
            flex-flow: row nowrap;
            justify-content: center;
            li{
              border:0px; margin:0px 20px; padding:0px 0px;
              img{
                width: 80px;
                height: 80px;
              }
              .Unicom
              {
                width:80px;
                height:80px ;
                position: relative;
                bottom: 8px;
              }
              .mobile
              {
                width:80px;
                height:80px ;
                position: relative;
                top: 2px;
              }
            }
          }
          .subtileconpon{
            font-size: 18px;
            text-align: left;
            padding-top: 15px;
            padding-left: 15px;
            .right{
              font-size: 16px;
              color: #8a8a8a;
            }
          }
          // 活动卡片已抢光
          .eventscardsold{
            width: 94px;
            height: 100px;
            border: 3px solid #e1e1e1;
            border-radius: 5px;
            color: #999999;
            span{
              font-weight: 600;
              font-size: 14px;
            }
            .withbg{
              font-weight: 500;
              font-size: 12px;
              zoom: .6;
              background-color: #999999;
              color: #fff;
              padding: 6px 15px;
              border-radius: 20px;
            }
          }
          // 活动卡片选中
          .cardcontainer
          {
            margin-top: 10px;
          }

          .eventscardon{
            width: 124px;
            height: 130px;
            border-radius: 5px;
            border: 3px solid $ColorDeepblue;
            background-size: cover;
            h2{
              font-size: 28px;
            }
            span{
              font-weight: 600;
              font-size: 16px;
              color: $ColorDeepblue;
            }
            .withbg{
              font-weight: 500;
              font-size: 12px;
              zoom: .8;
              background-color: $ColorDeepblue;
              color: #fff;
              padding: 6px 15px;
              border-radius: 5px;
              margin-top: -12px;
            }
            .icon{
              width: 20px;
              height: 20px;
              position:relative;
              top: 8px;
              left: 48px;
            }
          }
          // 支付方式
          .explainTitle{
            font-size: 18px;
            font-weight: 400;
            padding: 5px 0px;
          }
          .explain{
            font-size: 18px;
            font-weight: 400;
            padding: 3px 0px;
            // margin-top: 5px;
            margin-bottom: 5px;
            span{
              font-size: 22px;
              font-weight: 600;
              color: $ColorDeepblue;
            }
          }
          .wechatbox{
            width: 100%;
            margin: 5px 0;
            padding: 5px 0px;
            white-space: nowrap;
            span{
              font-size: 20px;
            }
            .colorred{
              font-size: 14px;
              color: #fd0100;
            }
          }
        }
        // 中间单独活动说明
        .middlebox{
          height: 210px;
          margin-bottom: 80px;
          padding: 5px 20px;
        }

        .userAgreement{
          font-size: 10px;
          font-weight: 100;
          font-family:Georgia, 'Times New Roman', Times, serif;
          width: 100%;
          margin-top: 35px;
          p{
            color: #8a8a8a;
          }


          ::v-deep .van-checkbox__icon--checked .van-icon-success{

            background-color: #8a8a8a;
            border-color: #8a8a8a;
            }

          }
      }
    }
    // 底部充值按钮
    .footer{
      position: fixed;
      z-index: 999;
      bottom: 0;
      width: 100%;
      height: 80px;
      background: url('@/assets/vip.69b7f9ce.png') no-repeat center;
      background-size: cover;
      padding: 14px 24px;
      .left{
        color: #e8e5c7;
        position: relative;
        bottom: 0px;
        span{
          font-size: 20px;
          font-weight: 800;
        }
        strong{
          font-size: 38px;
          position:inherit;
        }
      }
      .right{
        position: relative;
        bottom:0px;

        color: #f6f6f6;
        font-size: 32px;
        padding-right: 10px;
        animation: scales-data-v-061c9136 1.4s linear alternate infinite;
        @keyframes scales-data-v-061c9136 {
          0%{transform: scale(1)}
          25%{transform: scale(1.2);}
          50%{transform: scale(1)}
          75%{transform: scale(1.2)}
        }
      }
    }
    // 话费券包说明
    .explaincontent{
      text-align: left;
      padding-bottom: 20px;
      h3{
        font-size: 18px;
        padding: 15px 0;
      }
      p{
        color: #999;
      }
      .large{
        font-size: 14px;
      }
      .little{
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
</style>
