import axios from 'axios'
import QueryString from 'qs'
import { Toast } from 'vant'
import { getToken, removeToken } from '@/utils/auth'

// 创建axios实例
//var baseURL = 'http://192.168.1.99/api/phonebill'
// var baseURL = 'https://phone.nannani.com/phoneMall'
 var baseURL = 'https://phone.nannani.com/phonebill'
//var baseURL = 'http://admin.ttc.com/phonebill'
// var baseURL = 'https://phone.nannani.com/phoneMallDev'
// var baseURL = 'http://127.0.0.1:6660/api'
var axiosService = axios.create({
    baseURL:baseURL,
    timeout:5000,
    headers:{
        'Cache-Control': 'no-cache' //重点清除接口的缓存
    }
})

// 请求拦截器
axiosService.interceptors.request.use(
    config => {
        // if () {
          // let each request carry token
          // ['X-Token'] is a custom headers key
          // please modify it according to the actual situation
          config.headers['pbtoken'] = getToken()
          config.headers['channelId'] = localStorage.getItem('channelId')
          config.headers['WEP'] = '3flKbt-7m8a?r8H.ZZo'
        // }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
axiosService.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code === 2016){
          return res
        }                 //0
        if (res.code !== 0) {
            Toast.fail({
                message:res.msg || 'Error',
                duration: 2000
            })
            if (res.code === 2000) {
                Toast.fail({
                    message:'登录已过期,请重新登录',
                    duration: 2000
                })
                // this.$router.push('/login')
                setTimeout(()=>{
                  window.open('/login','_self')
                },300)
                removeToken()
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        }else{
            return res
        }
    },
    error => {
        console.log('err' + error)
        Toast.fail({
            message:'error.message',
            duration: 2000
        })
        return Promise.reject(error)
    }
)
const service = {
    get(url) {
      return axiosService.get(url)
    },
    getByParam(url, data) {
      return axiosService.get(url + '?' + QueryString.stringify(data))
    },
    postByParams(url, params) {
      return axiosService.post(url + '?' + QueryString.stringify(params))
    },
    post(url) {
      return axiosService.post(url)
    },
    getBaseURL() {
      return baseURL
    },
    postByData(url, data) {
      return axiosService.post(url, data)
    }
}

export default service
