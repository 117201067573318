import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入重置样式
import '@/style/reset.css'
// 引入全局公共样式
import '@/style/index.css'
// 引入vant按需加载组件
import '@/plugins/vant.js'
Vue.config.productionTip = false
// import '@/permission' // permission control
// 引入复制文本插件
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
