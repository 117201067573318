import request from '@/utils/request'

// 获取券包页信息
export function getpageInfo(data) {
    return request.getByParam('/Home/getPageInfo', data)
}

// 购买券包
export function payForCoupon(data) {
    return request.postByData('/pay/phoneCouponPay',data)
}

// 查询券包是否购买成功
export function searchOrder(data) {
    return request.getByParam('/order/queryOrderState',data)
}

// 登录
export function login(data) {
    return request.postByData('/user/login',data)
}

// 获取登录页短信验证码
export function getcapcha(data) {
    return request.postByData('/user/sendSms',data)
}

// 获取所有用户订单
export function getOrderList(data) {
    return request.getByParam('/order/getOrderList',data)
}

// 查询套餐
export function getqueryCoupon() {
    return request.get('/coupon/queryCouponMeal')
}

// 查询优惠券
export function getDiscountCoupon(data) {
    return request.getByParam('/coupon/getUserCouponList',data)
}

// 话费充值
export function payphone(data) {
    return request.postByData('/pay/phonePay',data)
}

// 取消话费充值订单
export function cancelorder(data) {
    return request.postByData('/order/cancelOrder',data)
}

// 继续支付
export function continuePay(data) {
    return request.postByData('/pay/continuePhonePay',data)
}

// 支付宝审核H5页面
export function aliWebPay(data) {
    return request.postByData('/Pay/aliWebPay', data)
}